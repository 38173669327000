// Hardcoded - Avoid at all costs

// Overall

export enum Tenants {
  Infraflyt = 1,
  Laje = 8,
  Elvia = 139,

  Lillehammer = 122,
  Ullensaker = 255,
  Frogn = 213,

  RingerikskraftNettAS = 71,
  OteraInfra = 111,
  // is different ID in UAT than in prod, but id 4 is disabled
  OterUAT = 4,
  TestNettpartner = 493,
  Infratek = 29,

  Linja = 487,
  LinjaUAT = 2,

  Hurdal = 219,
  Bergen = 551,
  BergenVeilys = 625,
  Akershus = 696,
  VestlandFylkeBergen = 636,
}

export enum TenantsPublic {
  Bergen = 'a4fe1e30-102f-4daf-b8fe-88d3d007189d',
  Hurdal = '8f7d10c8-a715-4ec4-8ba9-6d4c2a621579',
  BergenVeilys = '89e8615e-4eeb-4f78-b80c-1516fb1171b9',
  Akershus = '783a79d7-dbc5-44b5-a456-da5e9097ae2e',
  VestlandFylkeBergen = '6489be8b-3fe0-4ec4-bfea-9c8e8e76fc4f',
}

export enum Modules {
  Partner = 4,
  Byggestrom = 5,
  Timer = 6,
  Ressurs = 7,
  VaktOgBeredskap = 8,
  Veilys = 9,
  Utstyr = 10,
  Henvendelse = 12,
  Vedlikehold = 13,
  Energi2 = 14,
  Ett = 15,
  Arkiv = 16,
  Oversiktsliste = 17,
  Public = 18,
  Admin = 19,
  Telekom = 22,
  KommunePartner = 23,
  KommuneOppdrag = 24,
  Jernbane = 25,
  Innmåling = 27,
  Geobilder = 28,
  Elektro = 29,
}

export enum Role {
  Admin = 1,
  AppOwner = 2,
  Saksbehandler = 3,
  Montør = 4,
  Analyse = 5,
  SuperBruker = 6,
  UtstyrsAdmin = 7,
  MontørLeder = 8,
  RessursAnsvarlig = 9,
  Gullbruker = 10,
  Arkiv = 11,
  DokAnsvarlig = 12,
}

// Request

export enum RequestCategoryGroups {
  Gatelys = 1,
}

export enum RequestCategories {
  Byggestrøm = 29,
  ByggestrømTilbud = 43,
  EtGatelys = 1,
  FlereGatelys = 2,
  MørktOmråde = 3,
  PublicEtGatelys = 33,
  PublicFlereGatelys = 38,
  PublicMørktOmråde = 39,
  ForslagTilNySti = 60,
  InfoOmEksisterendeSti = 61,
}

export const PublicRequestCategories = [
  RequestCategories.EtGatelys,
  RequestCategories.FlereGatelys,
  RequestCategories.MørktOmråde,
  RequestCategories.PublicEtGatelys,
  RequestCategories.PublicFlereGatelys,
  RequestCategories.PublicMørktOmråde,
];

export enum RequestStatuses {
  Ny = 2000,
  AvventVurderes = 2010,
  BehandletLest = 2020,
  Opprettet = 3000,
  UnderBehandling = 4000,
  Avvik = 4500,
  ProvisoriskRettet = 5000,
  Utført = 6000,
  Avsluttet = 8000,
  Utgår = 8010,
  FakturaKontrollert = 8020,
}

// JSreport

export enum ReportKeys {
  MangellisteInspeksjon = 'HyKYI-9DH',
  MangellisteKvalitetskontroll = 'Hyc0IWqWV',
  Feilrettingsskjema = 'ryRFlL9W4',
  Jordlogg = 'BJll0R7hir',
  LFSLinje = 'r1gEKKdWnr',
  LFSKabel = 'rkgFcKdW2r',
  Test = 'By3zMp6XN',
  Request = 'B1x8i7JQor',
  PhotoReportLarge = 'BJxFVM9uN',
  PhotoReportSmall = 'AxyTTqHgoy',
  Excel = 'rkQPeynC7',
}

// Project

export enum ProjectTypes {
  Beredskap = 16,
  Bilde = 7,
  Bilde2 = 29,
  Byggestrom = 2,
  Byggeplasstrom = 24,
  DistribusjonsnettProsjekt = 19,
  Prosjektering = 31,
  VaktOgBeredskap = 32,
  InspeksjonNettstasjon = 59,
  Masteskift = 4,
}

export enum StatusGroups {
  Aktive = 1,
  Avsluttede = 2,
  Innkommende = 3,
  Prosjektering = 4,
}

export enum ProjectStatusIds {
  Aktivt = 2001,
  Startet = 2002,
  TekniskUtført = 2005,
  Avsluttet = 2008,
  Avvik = 2018,

  TilMontering = 2045,
  KundeAkseptertVilkår = 2052,
  KundeLagtTilBilde = 2053,
  KlartFraKunde = 2054,
  SendtTilKunde = 2055,

  AvvikProsjektering = 2061,
  TekniskUtførtProsjektering = 2062,
}

export enum ProjectDetails {
  RequestedConnectionDate = 'd07d0aec-1b71-4b21-8c14-69528f9e06d5',
  ExecutingSupplierName = '28fffdce-d3b5-463f-9af0-61e30b136d4a',
  ExecutingSupplierEmail = '7ff7c3b1-380f-4c43-9e4e-eafb8bd48b34',
  DeficitListSentAt = 'bd062828-600f-4c6b-a485-96def6f51e95',
  CustomerEmail = 'd121dd81-4be1-47ed-9892-a829c41a763c',
}

// Files

export enum FileCategories {
  TilKunde = 19,
  Henvendelse = 11,
}

export enum MaintenanceObjectTypes {
  Elbillader = 2,
  Kum = 4,
  Elbelysningspunkt = 6,
  Tilknytningsskap = 7,
  Node = 8,
  Kabel = 9,
  Nettstasjon = 11,
  Tennpunkt = 12,
  Pumpestasjon = 14,
  Gangfelt = 15,
  ElbilForsyningspunkt = 16,
  Skilt = 18,
  Stikkrenner = 19,
  Kontaktor = 21,
  Mast = 23,
  Fotocelle = 24,
  HsKabel = 25,
  Fordelingsskap = 26,
  LsKabel = 28,
  Sti = 29,
  Søppelkasse = 30,
  Rode = 31,
  Strøsandkasse = 34,
  Veisperringer = 35,
  Rørgate = 38,
  Kraftstasjon = 39,
  Bryter = 40,
  HsLuftlinje = 41,
  LsLinje = 42,
}

export enum ChecklistStatuses {
  TekniskUtført = 300,
  Signert = 350,
  Avvik = 400,
  RapportSendtLåst = 500,
  FerdigLåst = 600,
  MottattLåst = 800,
}

export enum CheckpointOptions {
  UserSignature = 'UserSignature',
  UserButton = 'UserButton',
  AnonymousUser = 'AnonymousUser',
  Initials = 'Initials',
}

export enum DocumentationStatuses {
  UnderProsjektering = 1,
  TilKontroll = 2,
}

export enum MaintenanceProps {
  Vedlikeholdsansvarlig = 30,
  TilførselFraKurs = 35,
  TilførselFraTilknytningsskap = 36,
  KabelLinje = 62,
  TilførselFraKurs2 = 69,
  TilførselFraTilknytningsskap2 = 68,
  TilførselFraFotocelle = 91,
  TilførselFraKontaktor = 92,
  Fotocelle = 93,
  Tennpunktnummer = 182,
  Fotocelle2 = 202,
  Kontaktornummer = 203,
}

export enum MaintenanceObjectStatuses {
  IngenPågående = 1,
  TilVurdering = 2,
  Pågår = 3,
}

export enum MaintenanceObjectStates {
  AltOk = 1,
  MastMåByttes = 3,
  MåVedlikeholdes = 4,
  InneholderPunktForOppsyn = 5,
  IkkeUndersøkt = 6,
  ArmaturMåByttes = 7,
}

export enum SystemJobs {
  UpdateMODate = 7,
  CreateMaintenanceIssues = 10,
  CreateProjectIssues = 11,
  RequireNote = 12,
  RequireIssues = 13,
  TodoRequirement = 14,
}

export enum IssueStatuses {
  Ubehandlet = 100,
  Akseptert = 1000,
  UtførtIFelt = 1500,
  AvvikRettet = 1700,
  Avvist = 2000,
  Utsatt = 3000,
}

export enum ChecklistTemplateFolders {
  Generell = -1,
  Prosjektering = 12,
  Dokumentasjon = 16,
  Innmåling = 18,
}

export enum ChecklistTemplates {
  TEST = 114,

  InternkontrollLampepunkt = 251,
  InternkontrollNode = 113,
  ACladerÅrligKontroll = 234,
  DCladerÅrligKontroll = 235,
  ForsyningspunktElbilÅrligKontroll = 233,
  OmrådetElbilÅrligKontroll = 236,
  KontrollpunktslisteForLVLinjer = 336,
  SjaFseRisiko = 352,
  H1Småjobber = 180,
}

export enum ValueListGuids {
  DocManagers = '5536490d-7e56-4fd4-a1f4-da4dce7d86ea',
}

export enum DepartmentIds {
  LajeSør = 19,
}

export enum ChecklistFolders {
  Innmåling = 18,
}

export enum PartnerPermissions {
  MaintenanceCreate = 'f3e1530e-8bc0-47f8-8d68-a8fdedd8963b',
  MaintenanceUpdate = '1c8282dc-5674-41eb-90c1-af7a36835cff',
  MaintenanceRead = 'd1ad2fc6-7319-48b6-9f70-b0820ca8bd33',
  MaintenanceReadAll = '2742bfdb-2715-4858-a9df-4156f8546bb2',
  MaintenanceDelete = '7c907321-6d84-4f99-b36c-ac43496151a1',
  MaintenanceAutoApprove = 'b8106a76-03ed-4879-acc0-e7969605e924',
  MaintenanceCreateRequest = 'd611cbaf-1276-4750-8128-1bf2691f0b55',
  MaintenancePropertiesUpdate = 'ba31e74c-ee8d-4e82-af61-fc3c2ba34442',

  RequestsReadAll = 'ca3c3bd1-7f20-433a-89a7-2f0a5c72afb4',
  RequestsReadSingle = '003b3af3-fb6c-4e49-8a72-3432f2f59c39',
  RequestsAutoApprove = 'cf95938b-2525-4e27-872d-2cc81622c9aa',

  ProjectReadAll = 'e7ad8db1-ff53-4aef-a904-24fde45e5bd5',
  ProjectDocumentsReadAll = 'b537fbab-a93c-42b4-ab12-419d7ab61178',
  ProjectChecklistsReadAll = '8c0fb218-5b4f-4a5c-b6a8-091ddb3375d4',
  ProjectCreate = '67845271-42c1-4a8d-b753-6d34681c8cf6',

  ByggestromProductReadAll = '36cd4772-e499-467a-a3eb-7944d1e51217',
  ByggestromPowerboxesReadAll = 'a8bf7d62-991b-4501-8ff0-117126fbc8d9',

  ShareProjectWith = '873617d2-4cb4-43d6-b391-c6eb89c01640',

  IssuesRead = 'e9e5c6d2-2d79-4ff0-a2ea-2ee03c3f6465',

  DepartmentPartner = '56a2703f-5177-429e-a029-6d2d9932ec64',
  TransferRequestOwner = 'bec55c18-f91b-40b9-8cb6-9132f41bc8b1',
  GenericAccess = 'b4269650-cc9c-489c-b662-bbd36ff582c2',
}
