import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { api, createQueryParams, genericApiErrorHandler } from '/@tools/api';
import { convertDateToString } from '/@tools/date-utils';
import {
  Permission,
  Role,
  AdminUser,
  AdminUserFormCreate,
  AdminUserFormUpdate,
  AdminArticle,
  AdminArticleForm,
  AdminArticleFormDetails,
  FromApi,
  ProjectFolder,
  ProjectFolderForm,
  ArticleType,
  DynamicProperty,
  IntegrationError,
  TermChecklistTemplate,
  TenantPublicFile,
  CreatePublicFileForm,
  ProjectPriority,
  ProjectPriorityForm,
  TenantRequestCategory,
  CreateEnergyPartnerForm,
  CreateEnergyPartnerUserForm,
  EnergyPartner,
  CreateRequestCategoriesFavoriteTenantsForm,
} from './admin.types';
import {
  mapArticle,
  mapArticleType,
  mapArticlesForm,
  mapDynamicProperty,
  mapIntegrationError,
  mapProjectFolder,
  mapTenantPublicFile,
  mapTenantRequestCategory,
  mapTermChecklistTemplates,
} from './admin.mappers';

import { ProjectType } from '/@features/project/project.types';
import { mapProjectType } from '/@features/project/project.mappers';
import { useTenantStore } from '/@features/tenant';
import { fileArrayBuffer } from '/@features/file/file.tools';
import { guid } from '/@tools/guid';

function mapPermission(p: any): Permission {
  return {
    guid: p.Guid,
    name: p.Name,
    description: p.Description,
    userSelectable: p.UserSelectable,
    activeInFrontend: p.ActiveInFrontend,
    activeInBackend: p.ActiveInBackend,
  };
}

function mapRole(r: any): Role {
  return {
    id: r.Id,
    name: r.Name,
    permissions: new Map(
      r.Permissions.filter((i: any) => i.ActiveInFrontend).map((i: any) => [
        i.Guid,
        mapPermission(i),
      ]),
    ),
    selectable: r.SelectableForEndUser,
    canSetPermission: r.PermissionRequierdToDeligate,
    activeInFrontend: r.ActiveInFrontend,
  };
}

function mapAdminUser(u: any): AdminUser {
  return {
    id: u.UserId,
    name: [u.FirstName, u.LastName].join(' '),
    firstName: u.FirstName,
    lastName: u.LastName,
    betaUser: u.BetaUser,
    email: u.Email,
    employeeNumber: u.EmployeeNumber,
    mobile: u.Mobile,
    dateOfBirth: u.DateOfBirth,
    hmsCardNumber: u.hmsCardNumber,
    roles: u.Roles.map((role: any) => role.Id),
    createdAt: new Date(u.CreatedDate),
    lastLoggedInAt: u.LastLoggedIn ? new Date(u.LastLoggedIn) : null,
    caseworkerForGridowner: u.CaseworkerForGridowner,
    enabled: !u.IsDisabled,
    goldpartnerIds: u.GoldpartnerIds,
    showStatusNotificationPage: u.ShowStatusNotificationPage,
    settings: {
      showElektroProjectMapReportErrorMO: u.Settings.ShowElektroProjectMapReportErrorMO,
      showEnergiProjectMapReportErrorMO: u.Settings.ShowEnergiProjectMapReportErrorMO,
      showVeilysProjectMapReportErrorMO: u.Settings.ShowVeilysProjectMapReportErrorMO,
      showFinishedProjects: u.Settings.ShowFinishedProjects,
      betaUser: u.Settings.BetaUser,
      showStatusNotificationPage: u.Settings.ShowStatusNotificationPage,
    },
    qualificationIds: u.QualificationIds,
    departmentId: u.DepartmentId,
    department: u.DepartmentName,
    comment: u.Comment,
    nonBillableUser: u.NonBillableUser,
    hiddenModuleIds: u.BlacklistedModuleIds,
  };
}

export const useAdminStore = defineStore('admin', () => {
  const roles = ref<Map<number, Role>>(new Map());

  function loadRoles() {
    return api
      .get('/roles')
      .then(({ data }) => {
        roles.value = new Map(
          data
            .toSorted((a: any, b: any) => a.Name.localeCompare(b.Name))
            .map((i: any) => [i.Id, mapRole(i)]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function loadTenantRoles(tenantId: number): Promise<Map<number, string>> {
    return api
      .get(`/tenants/${tenantId}/roles`)
      .then(({ data }) => new Map(data.map((r: any) => [r.Id, r.Name])))
      .catch(genericApiErrorHandler);
  }

  const permissions = ref<Map<string, Permission>>(new Map());

  function loadPermissions() {
    return api
      .get('/permissions', { params: { activeInFrontend: true } })
      .then(({ data }) => {
        permissions.value = new Map(data.map((i: any) => [i.Guid, mapPermission(data)]));
      })
      .catch(genericApiErrorHandler);
  }

  const users = ref<Map<number, AdminUser>>(new Map());

  function loadUsers() {
    return api
      .get('/users')
      .then(({ data }) => {
        users.value = new Map(data.map((i: any) => [i.UserId, mapAdminUser(i)]));
      })
      .catch(genericApiErrorHandler);
  }

  function createUser(form: AdminUserFormCreate) {
    return api
      .post('/users', {
        FirstName: form.firstName,
        LastName: form.lastName,
        Email: form.email,
        Mobile: form.mobile,
        EmployeeNumber: null,
        DepartmentId: form.departmentId,
        BlacklistedModuleIds: form.hiddenModuleIds,
        UserRoleIds: form.roles,
        QualificationIds: form.qualificationIds,
      })
      .then(() => loadUsers())
      .catch(genericApiErrorHandler);
  }

  function updateUser(userId: number, form: AdminUserFormUpdate) {
    return api
      .put(`/users/${userId}`, {
        FirstName: form.firstName,
        LastName: form.lastName,
        Comment: form.comment,
        EmployeeNumber: form.employeeNumber,
        Mobile: form.mobile,
        DateOfBirth: convertDateToString(form.dateOfBirth),
        hmsCardNumber: form.hmsCardNumber,
        UserRoleIds: form.roles,
        DepartmentId: form.departmentId,
        CaseworkerForGridOwnerId: form.caseworkerForGridowner,
        // ???
        NonBillableUser: form.nonBillableUser,
        GoldpartnerIds: form.goldpartnerIds,
        QualificationIds: form.qualificationIds,
        BlacklistedModuleIds: form.hiddenModuleIds,
        // always nothing
        ExemptPermissionGuids: form.exemptPermissionGuids,
      })
      .then(() => loadUsers())
      .catch(genericApiErrorHandler);
  }

  function disableUser(userId: number) {
    return api
      .put(`/users/${userId}/disable`, null, { params: { disable: true } })
      .then(() => loadUsers())
      .catch(genericApiErrorHandler);
  }

  function loadUserLog(userId: number) {
    return api
      .get(`/users/${userId}/userlogs`)
      .then(({ data }) => {
        return new Map(
          data.map((i: any) => [
            i.Id,
            {
              id: i.Id,
              guid: i.Guid,
              userId: i.UserId,
              type: i.Type,
              description: i.Description,
              createdByUser: i.CreatedByUserName,
              createdByUserId: i.CreatedByUserId,
              createdAt: new Date(i.CreatedAt),
            },
          ]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  const articles = ref<Map<number, AdminArticle>>(new Map());

  function loadArticles(): Promise<void> {
    return api
      .get(`articles`)
      .then(({ data }) => {
        data.forEach((a: any) => {
          articles.value.set(a.Id, mapArticle(a));
        });
      })
      .catch(genericApiErrorHandler);
  }

  function createArticle(form: AdminArticleForm) {
    return api
      .post(`articles`, mapArticlesForm(form))
      .then(() => loadArticles())
      .catch(genericApiErrorHandler);
  }

  function updateArticle(articleId: number, form: AdminArticleForm) {
    return api
      .put(`articles/${articleId}`, mapArticlesForm(form))
      .then(() => loadArticles())
      .catch(genericApiErrorHandler);
  }

  function updateArticleDetails(articleId: number, form: AdminArticleFormDetails) {
    return api
      .put(`/articles/${articleId}/details`, {
        RelatedArticleId: form.relatedArticleId,
        SortOrder: form.sortOrder,
        HasLabel: form.hasLabel,
        HasTime: form.hasTime,
        Labels: [...form.labels],
        ArticleTypeId: form.typeId,
        UnitId: form.unitId,
      })
      .then(() => loadArticles())
      .catch(genericApiErrorHandler);
  }

  function deleteArticle(articleId: number): Promise<void> {
    return api
      .delete(`articles/${articleId}`)
      .then(() => {
        articles.value.delete(articleId);
      })
      .catch(genericApiErrorHandler);
  }

  const articleTypes = ref<Map<number, ArticleType>>(new Map());

  function loadArticleTypes() {
    return api
      .get(`/articletypes`)
      .then(({ data }) => {
        articleTypes.value = new Map(data.map((a: any) => [a.Id, mapArticleType(a)]));
      })
      .catch(genericApiErrorHandler);
  }

  const projectFolders = ref<Map<number, ProjectFolder>>(new Map());

  function loadProjectFolders(disabled = false) {
    return api
      .get(`/projectfolders${disabled ? '?includeDisabled=true' : ''}`)
      .then(({ data }) => {
        data.forEach((folder: any) => {
          projectFolders.value.set(folder.Id, mapProjectFolder(folder));
        });
      })
      .catch(genericApiErrorHandler);
  }

  function createProjectFolder(form: ProjectFolderForm) {
    return api
      .post(`/projectfolders`, {
        Name: form.name,
        Geometry: form.geometry,
        DepartmentId: form.departmentId,
        FolderNumber: form.folderNumber,
      })
      .catch(genericApiErrorHandler);
  }

  function updateProjectFolder(folderId: number, form: ProjectFolderForm) {
    return api
      .put(`/projectfolders/${folderId}`, {
        Name: form.name,
        Disabled: !form.enabled,
        DepartmentId: form.departmentId,
        FolderNumber: form.folderNumber,
      })
      .catch(genericApiErrorHandler);
  }

  function loadAdminProjectProperties(
    moduleId: number,
    tenantId: number,
  ): Promise<Map<number, DynamicProperty>> {
    const queries = createQueryParams(new Map([['moduleIds', moduleId]]), {
      useGoldPartners: true,
    });
    return api
      .get(`/Tenants/${tenantId}/ProjectTypeProperties?${queries}`)
      .then(
        ({ data }: { data: Array<any> }) =>
          new Map(
            data
              .sort((a, b) => a.SortIndex - b.SortIndex)
              .map((property) => [property.Id, mapDynamicProperty(property, [moduleId])]),
          ),
      )
      .catch(genericApiErrorHandler);
  }

  function loadAdminProjectTypes(
    moduleId: number,
    tenantId: number,
  ): Promise<Map<number, ProjectType>> {
    const queries = createQueryParams(new Map([['tenantIds', tenantId]]));
    return api
      .get(`/modules/${moduleId}/projecttypes?${queries}`)
      .then(
        ({ data }: { data: Array<any> }) =>
          new Map(data.map((type) => [type.Id, mapProjectType(type, moduleId, tenantId)])),
      )
      .catch(genericApiErrorHandler);
  }

  function updateProjectTypeProperty(
    typeId: number,
    tenantId: number,
    property: { PropertyId: number; Hidden: boolean },
  ) {
    return api
      .put(`/tenants/${tenantId}/ProjectTypes/${typeId}/Properties`, [property])
      .catch(genericApiErrorHandler);
  }

  function loadIntegrationErrors(tenantId: number): Promise<Map<number, IntegrationError>> {
    return api
      .get(`/Tenants/${tenantId}/IntegrationErrorLogs`)
      .then(({ data }) => {
        return new Map(data.map((error: FromApi) => [error.Id, mapIntegrationError(error)]));
      })
      .catch(genericApiErrorHandler);
  }

  function retryIntegrationErrors(tenantId: number, integrationLogIds: number[]): Promise<void> {
    return api
      .post(`/Tenants/${tenantId}/IntegrationErrorLogs`, {
        IntegrationLogIds: integrationLogIds,
      })
      .catch(genericApiErrorHandler);
  }

  function deleteIntegrationError(tenantId: number, integrationLogId: number): Promise<void> {
    return api
      .delete(`/Tenants/${tenantId}/IntegrationErrorLogs/${integrationLogId}`)
      .catch(genericApiErrorHandler);
  }

  function updateProjectTypeStatus(
    typeId: number,
    statusId: number,
    { hidden }: { hidden: boolean },
  ) {
    return api
      .put(`/projecttype/${typeId}/status/${statusId}`, {
        Hidden: hidden,
      })
      .catch(genericApiErrorHandler);
  }

  const tenantId = computed(() => useTenantStore().tenant.id);

  function loadTermChecklistTemplates(): Promise<TermChecklistTemplate[]> {
    return api
      .get(`/Tenants/${tenantId.value}/TermsChecklistTemplates`)
      .then(({ data }) => data.map((checklist: any) => mapTermChecklistTemplates(checklist)))
      .catch(genericApiErrorHandler);
  }

  function loadTenantPublicFiles(): Promise<TenantPublicFile[]> {
    return api
      .get(`/Tenants/${tenantId.value}/PublicFiles`)
      .then(({ data }) => data.map((file: any) => mapTenantPublicFile(file)))
      .catch(genericApiErrorHandler);
  }

  function createTenantPublicFile(form: CreatePublicFileForm, file: File) {
    return fileArrayBuffer(file).then((arrayBuffer) => {
      const formData = new FormData();

      formData.append('File', new File([arrayBuffer], file.name));

      formData.append('Name', form.name || file.name);
      formData.append('ShortName', form.name || file.name);
      formData.append('Description', form.description);
      formData.append('Version', form.version);
      formData.append('SortNumber', '100');
      formData.append('FileGuid', guid());

      return api
        .post(`tenants/${tenantId.value}/publicfiles`, formData)
        .catch(genericApiErrorHandler);
    });
  }

  function updatePublicFileVersion(fileId: number, version: string, file: any) {
    return fileArrayBuffer(file).then((arrayBuffer) => {
      const formData = new FormData();

      formData.append('File', new File([arrayBuffer], file.name));
      formData.append('Version', version);
      formData.append('SortNumber', '100');
      formData.append('FileGuid', guid());

      return api
        .post(`Tenants/${tenantId.value}/PublicFiles/${fileId}/PublicFileVersion`, formData)
        .catch(genericApiErrorHandler);
    });
  }

  type UpdateTenantPublicFileForm = { name: string | null; description: string | null };
  function updateTenantPublicFile(publicFileId: number, form: UpdateTenantPublicFileForm) {
    return api
      .put(`/Tenants/${tenantId.value}/PublicFiles/${publicFileId}`, {
        Name: form.name,
        Description: form.description,
        Shortname: '',
      })
      .catch(genericApiErrorHandler);
  }

  function loadRequestCategoriesFavoriteTenants(): Promise<Map<number, TenantRequestCategory>> {
    return api
      .get(`/requestcategoriesfavoritetenants`)
      .then(({ data: tenants }) => {
        return new Map(
          tenants
            .sort((a: any, b: any) => {
              if (a.RequestCategoryGroupId > b.RequestCategoryGroupId) return 1;
              if (a.RequestCategoryGroupId < b.RequestCategoryGroupId) return -1;
              if (a.RequestCategoryId > b.RequestCategoryId) return 1;
              if (a.RequestCategoryId < b.RequestCategoryId) return -1;
            })
            .map((t: any) => [t.Id, mapTenantRequestCategory(t)]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function createRequestCategoriesFavoriteTenants(
    form: CreateRequestCategoriesFavoriteTenantsForm,
  ) {
    return api
      .post(`/requestcategories/${form.categoryId}/favoritetenants`, {
        FavoriteTenantId: form.partnerId,
        CreateProjectFromRequest: form.createProject,
        SendNotifications: form.sendNotifications,
        DepartmentId: form.departmentId,
      })
      .catch(genericApiErrorHandler);
  }

  function updateRequestCategoriesFavoriteTenants(
    form: CreateRequestCategoriesFavoriteTenantsForm,
  ) {
    return api
      .put(`/requestcategories/${form.categoryId}/favoritetenants/${form.partnerId}`, {
        CreateProjectFromRequest: form.createProject,
        SendNotifications: form.sendNotifications,
        DepartmentId: form.departmentId,
      })
      .catch(genericApiErrorHandler);
  }

  function deleteRequestCategoriesFavoriteTenants(categoryId: number, partnerId: number) {
    return api
      .delete(`/requestcategories/${categoryId}/favoritetenants/${partnerId}`)
      .catch(genericApiErrorHandler);
  }

  function deleteTenantPublicFile(publicFileId: number) {
    return api
      .delete(`/Tenants/${tenantId.value}/PublicFiles/${publicFileId}`)
      .catch(genericApiErrorHandler);
  }

  function loadTenantPublicFileGroups() {
    return api
      .get(`/Tenants/${tenantId.value}/PublicFileGroups`)
      .then(({ data }) => data)
      .catch(genericApiErrorHandler);
  }

  function addOptionPublicFile(optionId: number, fileId: number) {
    return api
      .post(`/Tenants/${tenantId.value}/ChecklistOption/${optionId}/PublicFiles/${fileId}`)
      .then(() => loadTermChecklistTemplates())
      .catch(genericApiErrorHandler);
  }

  function removeOptionPublicFile(optionId: number, fileId: number) {
    return api
      .delete(`/Tenants/${tenantId.value}/ChecklistOption/${optionId}/PublicFiles/${fileId}`)
      .then(() => loadTermChecklistTemplates())
      .catch(genericApiErrorHandler);
  }

  function mapPriority(priority: FromApi): ProjectPriority {
    return {
      id: priority.Id,
      name: priority.Name,
      sortIndex: priority.SortIndex,
      dueDateDays: priority.DueDateDays,
      requestPriorityIds: new Set(priority.RequestPriorityIds),
    };
  }

  const projectPriorities = ref<Map<number, ProjectPriority>>(new Map());

  const tenantStore = useTenantStore();

  function loadProjectPriorities(): Promise<Map<number, ProjectPriority>> {
    return api
      .get(`/Tenants/${tenantStore.tenant.id}/ProjectPriorities`)
      .then(({ data }) => {
        projectPriorities.value = new Map(data.map((p: any) => [p.Id, mapPriority(p)]));
        return projectPriorities.value;
      })
      .catch(genericApiErrorHandler);
  }

  function createProjectPriority(form: ProjectPriorityForm) {
    return api
      .post(`/Tenants/${tenantStore.tenant.id}/ProjectPriorities`, {
        Name: form.name,
        SortIndex: form.sortIndex,
        DueDateDays: form.dueDateDays,
        RequestPriorityIds: [...form.requestPriorityIds],
      })
      .catch(genericApiErrorHandler);
  }

  function updateProjectPriority(priorityId: number, form: ProjectPriorityForm) {
    return api
      .put(`/Tenants/${tenantStore.tenant.id}/ProjectPriorities/${priorityId}`, {
        Name: form.name,
        SortIndex: form.sortIndex,
        DueDateDays: form.dueDateDays,
        RequestPriorityIds: [...form.requestPriorityIds],
      })
      .catch(genericApiErrorHandler);
  }

  function deleteProjectPriority(priorityId: number) {
    return api
      .delete(`/Tenants/${tenantStore.tenant.id}/ProjectPriorities/${priorityId}`)
      .then(() => {
        projectPriorities.value.delete(priorityId);
      })
      .catch(genericApiErrorHandler);
  }

  const energyPartners = ref<Map<number, EnergyPartner>>(new Map());

  function mapEnergyPartner(partner: any): EnergyPartner {
    return {
      id: partner.Id,
      name: partner.Name,
      orgNr: partner.OrgNumber,
      municipalities: partner.Municipalities,
      address: partner.Address,
      postalNumber: partner.PostalNumber,
      postalPlace: partner.PostalPlace,
      guid: partner.Guid,
      isPartner: partner.IsPartner,
    };
  }

  function loadEnergyPartners() {
    return api
      .get('/v2/partners')
      .then(({ data }) => {
        data.forEach((partner: any) => {
          energyPartners.value.set(partner.Id, mapEnergyPartner(partner));
        });
      })
      .catch(genericApiErrorHandler);
  }

  function createEnergyPartner({ name, orgNr }: Partial<CreateEnergyPartnerForm>) {
    return api
      .post(`/v2/partners`, {
        Name: name,
        OrgNumber: orgNr,
      })
      .then(({ data: partnerId }) => {
        loadEnergyPartners();
        return partnerId;
      })
      .catch(genericApiErrorHandler);
  }

  function updateEnergyPartner(partnerId: number, form: CreateEnergyPartnerForm) {
    return api
      .put(`/v2/partners/${partnerId}`, {
        Name: form.name,
        OrgNumber: form.orgNr,
        Address: form.address,
        PostalNumber: form.postalNumber,
        PostalPlace: form.postalPlace,
      })
      .then(() => loadEnergyPartners())
      .catch(genericApiErrorHandler);
  }

  function deleteTenantEnergyPartner(partnerId: number) {
    return api
      .delete(`/subcontractors/${partnerId}`)
      .then(() => {
        energyPartners.value.delete(partnerId);
      })
      .catch(genericApiErrorHandler);
  }

  function createEnergyPartnerUser(tenantId: number, form: CreateEnergyPartnerUserForm) {
    return api
      .post(`/v2/partners/${tenantId}/users`, {
        Email: form.email,
        FirstName: form.firstName,
        LastName: form.lastName,
        PhoneNumber: form.phone,
      })
      .catch(genericApiErrorHandler);
  }

  type UserGroup = { name: string; number: number };

  const usergroups = ref<Map<number, UserGroup>>(new Map());

  function loadUsergroups() {
    return api
      .get(`usergroups`)
      .then(({ data }) => {
        usergroups.value = new Map(
          data.map((ug: any) => [ug.Id, { name: ug.Name, number: ug.Number }]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  const usergroupArticles = ref<Map<number, string>>(new Map());

  function loadUsergroupArticles(usergroupId: number) {
    return api
      .get(`usergroups/${usergroupId}/articles`)
      .then(({ data }) => {
        usergroupArticles.value = new Map(data.map((a: any) => [a.Id, a.Name]));
      })
      .catch(genericApiErrorHandler);
  }

  const usergroupUsers = ref(new Map());

  function loadUsergroupUsers(usergroupId: number) {
    return api
      .get(`usergroups/${usergroupId}/users`)
      .then(({ data }) => {
        usergroupUsers.value = new Map(
          data.map((u: any) => [u.Id, [u.FirstName, u.Lastname].join(' ')]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function loadCategoryOptions(tenantId: number, categoryId: number) {
    return api
      .get(`tenants/${tenantId}/requestcategories/${categoryId}`)
      .then(({ data }) => data)
      .catch(genericApiErrorHandler);
  }

  function addCategoryOptions(tenantId: number, categoryId: number, emails: string[]) {
    return api
      .post(`tenants/${tenantId}/requestcategories/${categoryId}`, { EmailsOnCreation: emails })
      .catch(genericApiErrorHandler);
  }

  function updateCategoryOptions(tenantId: number, categoryId: number, emails: string[]) {
    return api
      .put(`tenants/${tenantId}/requestcategories/${categoryId}`, { EmailsOnCreation: emails })
      .catch(genericApiErrorHandler);
  }

  function removeCategoryOptions(tenantId: number, categoryId: number) {
    return api
      .delete(`tenants/${tenantId}/requestcategories/${categoryId}`)
      .catch(genericApiErrorHandler);
  }

  return {
    roles,
    loadRoles,
    loadTenantRoles,

    permissions,
    loadPermissions,

    users,
    loadUsers,
    createUser,
    updateUser,
    disableUser,
    loadUserLog,

    articles,
    loadArticles,
    createArticle,
    updateArticle,
    updateArticleDetails,
    deleteArticle,
    articleTypes,
    loadArticleTypes,

    projectFolders,
    loadProjectFolders,
    createProjectFolder,
    updateProjectFolder,
    updateProjectTypeStatus,

    loadAdminProjectProperties,
    loadAdminProjectTypes,
    updateProjectTypeProperty,

    loadIntegrationErrors,
    retryIntegrationErrors,
    deleteIntegrationError,

    loadTermChecklistTemplates,

    loadTenantPublicFiles,
    createTenantPublicFile,
    updateTenantPublicFile,
    deleteTenantPublicFile,
    updatePublicFileVersion,

    loadTenantPublicFileGroups,
    addOptionPublicFile,
    removeOptionPublicFile,

    loadRequestCategoriesFavoriteTenants,
    createRequestCategoriesFavoriteTenants,
    updateRequestCategoriesFavoriteTenants,
    deleteRequestCategoriesFavoriteTenants,

    projectPriorities,
    loadProjectPriorities,
    createProjectPriority,
    updateProjectPriority,
    deleteProjectPriority,

    energyPartners,
    loadEnergyPartners,
    createEnergyPartner,
    updateEnergyPartner,
    deleteTenantEnergyPartner,
    createEnergyPartnerUser,

    usergroups,
    loadUsergroups,
    usergroupArticles,
    loadUsergroupArticles,
    usergroupUsers,
    loadUsergroupUsers,

    loadCategoryOptions,
    addCategoryOptions,
    updateCategoryOptions,
    removeCategoryOptions,
  };
});
